<template>
  <div class="page-general-info-5 animate__animated animate__fadeIn pt-4">
    <!-- <img :src="require('@/assets/banners/grl-info-5.png')" class="w-100 mb-4" alt="" /> -->

    <div v-if="informCharities">
      <h5 class="fs-la">Which charities?</h5>
      <div class="input-search-container mb-3">
        <div class="container-search-icon">
          <i class="fa-solid fa-magnifying-glass"></i>
        </div>
        <vSelect
          class="w-100 fs-md"
          label="name"
          :clearable="false"
          :options="charities"
          :placeholder="`Search Charities`"
          :multiple="false"
          @option:selected="(ev) => selectChange(ev, 'selectedCharities')"
          @open="onOpen"
          @close="onClose"
        >
          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center py-1">
              <img :src="`${urlBucket}/icons/${option.icon}`" width="30px" class="me-3" />
              <p class="mb-0 fs-md">{{ option.name }}</p>
            </div>
          </template>
        </vSelect>
      </div>

      <div class="chip-list-wrapper mb-2">
        <div
          class="chip me-1 mb-2"
          v-for="(item, index) in selectedCharities"
          :key="index"
          @click="deleteCompany(index, 'selectedCharities')"
        >
          <i class="fa-sharp fa-solid fa-circle-check"></i>
          <p class="ms-1 mb-0">{{ item.name }}</p>
        </div>
      </div>
    </div>

    <div v-if="informGyms">
      <h5 class="fs-la">Gyms</h5>
      <div class="input-search-container mb-3">
        <div class="container-search-icon">
          <i class="fa-solid fa-magnifying-glass"></i>
        </div>
        <vSelect
          class="w-100 fs-md"
          label="name"
          :clearable="false"
          :options="gyms"
          :placeholder="`Search Gyms`"
          :multiple="false"
          @option:selected="(ev) => selectChange(ev, 'selectedGyms')"
          @open="onOpen"
          @close="onClose"
        >
          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center py-1">
              <img :src="`${urlBucket}/${option.icon}`" width="30px" class="me-3" />
              <p class="mb-0 fs-md">{{ option.name }}</p>
            </div>
          </template>
        </vSelect>
      </div>

      <div class="chip-list-wrapper mb-2">
        <div
          class="chip me-1 mb-2"
          v-for="(item, index) in selectedGyms"
          :key="index"
          @click="deleteCompany(index, 'selectedGyms')"
        >
          <i class="fa-sharp fa-solid fa-circle-check"></i>
          <p class="ms-1 mb-0">{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import vSelect from "vue-select";
import { gyms, charities } from "../data/lists";
import { navigateTo } from '../router/index.js';

// @ is an alias to /src

export default {
  name: "PageGeneralInfo5",
  components: { vSelect },
  data() {
    return {
      gyms,
      charities,
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    selectChange(value, typeService) {
      // console.log("selectChange", value);

      let array = this[typeService];

      if (array.includes(value)) {
        let index = array.indexOf(value);
        array.splice(index, 1);
      } else {
        array.push(value);
      }

      this.$store.commit("formStore/setField", [typeService, array]);
    },
    deleteCompany(index, typeService) {
      let array = this[typeService];
      array.splice(index, 1);
      this.$store.commit("formStore/setField", [typeService, array]);
    },
    onOpen() {
      this.$store.commit("setField", ["zIndex", -1]);
    },
    onClose() {
      this.$store.commit("setField", ["zIndex", 9]);
    },
  },
  mounted() {

    const validatePage = () => {
      // let route = "/about-you";
      // const setRoute = () => {

      //   if (this.informMobile) {
      //     route = "/mobile-providers";
      //     return;
      //   }

      //   if (this.informLoyalty) {
      //     route = "/loyalties";
      //     return;
      //   }

      //   if (this.informBanks) {
      //     route = "/banks";
      //     return;
      //   }
      // };
      // setRoute();

      // this.validateInputs();

      navigateTo(setRoute(this.state))
      // if (!Object.values(this.errors).includes(true)) {
      // }
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT"){
        this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },

  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "referrer",
      "baseURL",
      "urlBucket",
      "selectedGyms",
      "selectedCharities",
      "informGyms",
      "informCharities",
      "informMobile",
      "informLoyalty",
      "informBanks",
      "informOpticians",
    ]),
  },
};
</script>
<style lang="scss">
.page-general-info-5 {
  .icon-search {
    color: #8a8a8a;
    float: left;
    margin-left: 15px;
    margin-top: -65px;
    position: relative;
    z-index: 2;
    font-size: 23px;
  }

  .v-select {
    color: black;
    font-weight: 600;

    .vs__dropdown-toggle {
      height: 55px;
      border: none;

    }

    .vs__search::placeholder {
      color: #8a8a8a;
    }
    .vs__dropdown-menu {
      p {
        font-weight: 200;
      }
    }

    .enter-manually {
      cursor: pointer;
      font-size: 12px;
      color: #8a8a8a;
      text-decoration: underline;
    }
  }

  .chip-list-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .chip {
      display: flex;
      align-items: center;
      padding: 7px;
      height: 30px;
      border-radius: 25px;
      background-color: #f1f1f1;
      cursor: pointer;

      p {
        color: black;
        font-size: 14px;
      }

      .fa-circle-check {
        color: #1e9d68;
        font-size: 16px;
      }
    }
  }
}
</style>
